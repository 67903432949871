<template>
  <div class="home">
    <div
      class="title-background centered grid grid-template-columns-1 padding-1"
    >
      <div class="right">
        <img
          class="main-image"
          src="https://i.ibb.co/mF6MrVM/logowideradar.png"
        />
      </div>
      <div class="left" style="padding-bottom: 20vh; margin-right: 5vw">
        <h2 class="info_top">
          É
          <b style="font-weight: bold; font-family: 'saira condensed'"
            >GESTÃO PÚBLICA DO BRASIL</b
          >
          <br />PRONTA PARA
          <b style="font-weight: bold; font-family: 'saira condensed'"
            >TODOS OS DESAFIOS.</b
          >
        </h2>
        <div
          style="
            margin-left: 20%;
            height: 2px;
            width: 80%;
            background-color: #28465f;
          "
        ></div>
      </div>
    </div>
    <h4 class="info_subtitle" style="margin-bottom: 0px; padding-bottom: 8vh">
      Monitoramento e planejamento estratégico da gestão pública, tecnologia a
      serviço da vida.
    </h4>
    <div
      style="height: 4.7vw; width: 100vw; background-color: #28465f"
      class="centered flex"
    >
      <h2 class="bar-title">
        A TECNOLOGIA DE INTELIGÊNCIA ARTIFICIAL A SERVIÇO DO BRASIL
      </h2>
    </div>
    <div class="centered grid grid-template-columns-1 padding-1">
      <img
        class="secondary-image appear-small"
        src="https://i.ibb.co/jhzv1fN/cidade-de-gramado.jpg"
      />
      <div class="left">
        <h4>PARA A SEGURANÇA</h4>
        <ul>
          <li>
            Detecção de Crimes: Utilizando análise preditiva, a IA identifica
            padrões e tendências suspeitas para prevenir crimes antes que
            ocorram
          </li>
          <li>
            Risco Zero: A tecnologia IA é capaz de processar dados em tempo real
            para antecipar e neutralizar ameaças à segurança pública
          </li>
          <li>
            Fraudes em Xeque: IA rastreia transações e comportamentos para
            detectar irregularidades, protegendo contra fraudes e corrupção
            eficientemente.
          </li>
          <li>
            Vigília Inteligente: Sistemas automatizados de IA providenciam
            monitoramento ininterrupto, aumentando a capacidade de resposta a
            incidentes e emergências.
          </li>
        </ul>
      </div>
      <img
        class="secondary-image vanish-small"
        src="https://i.ibb.co/jhzv1fN/cidade-de-gramado.jpg"
      />
    </div>
    <div
      class="centered grid grid-template-columns-1 light-background padding-1"
    >
      <img
        class="secondary-image"
        src="https://i.ibb.co/Hdq7J2z/foto-agentes.jpg"
      />
      <div class="right">
        <h4>PARA A SAÚDE</h4>
        <ul>
          <li>
            Interpretação de Exames: IA avançada trabalha ao lado de médicos
            para interpretar exames, melhorando a precisão e a velocidade do
            diagnóstico.
          </li>
          <li>
            Suporte Diagnóstico: A IA analisa sintomas e históricos médicos,
            auxiliando os profissionais de saúde a diagnosticar condições de
            forma mais eficaz. o
          </li>
          <li>
            Saúde Interativa: Chatbots alimentados por IA oferecem orientações
            iniciais de saúde, desafogando os sistemas e garantindo atendimento
            preliminar.
          </li>
          <li>
            Administração Clínica: Inteligência artificial otimiza toda a cadeia
            de gerenciamento clínico, desde agendamentos até administração de
            recursos hospitalares.
          </li>
        </ul>
        <router-link to="/health">
          <h4
            id="health"
            style="
              background-color: #333;
              border: solid;
              padding: 3%;
              color: #fff;
            "
          >
            Case de sucesso
          </h4>
        </router-link>
      </div>
    </div>
    <div class="centered grid grid-template-columns-1 padding-1">
      <img
        class="secondary-image appear-small"
        src="https://i.ibb.co/5GCPSgm/3.jpg"
      />

      <div class="left">
        <h4>PARA EDUCAÇÃO</h4>
        <ul class="text-too-long">
          <li>
            Tutoria Personalizada: IA analisa padrões de aprendizado dos alunos
            e adapta a instrução para abordar lacunas educacionais, tornando o
            ensino mais individualizado e eficaz.
          </li>
          <li>
            Avaliação Inteligente: Sistemas de IA realizam avaliações contínuas
            e feedbacks em tempo real, alavancando o desempenho estudantil e
            permitindo ajustes curriculares ágeis.
          </li>
          <li>
            Gestão Escolar Dinâmica: AI auxilia na administração escolar, desde
            a alocação de recursos até o monitoramento do desempenho acadêmico,
            aperfeiçoando operações e estratégias.
          </li>
          <li>
            Conteúdo Adaptativo: Plataformas de IA geram conteúdo educacional
            dinâmico que se ajusta ao ritmo de cada estudante, reforçando a
            compreensão e incentivando a auto didática.
          </li>
        </ul>
      </div>
      <img
        class="secondary-image vanish-small"
        src="https://i.ibb.co/5GCPSgm/3.jpg"
      />
    </div>

    <div
      class="centered grid grid-template-columns-1 light-background padding-1"
    >
      <img class="secondary-image" src="https://i.ibb.co/NKbzxJD/4.jpg" />
      <div class="right">
        <h4>INFRAESTRUTURA</h4>
        <ul>
          <li>
            Fiscalização Hídrica: IA monitora sistemas de distribuição para
            identificar vazamentos, evitando perdas e melhorando a gestão dos
            recursos hídricos.
          </li>
          <li>
            Urbanismo Eficiente: IA ajuda no planejamento urbano ao simular
            cenários futuros e sugerir soluções de design e construção
            sustentáveis e econômicas.
          </li>
          <li>
            Conservação Proativa: IA prevê defeitos em infraestrutura com base
            em dados históricos e atuais, permitindo manutenções antes que
            problemas se intensifiquem.
          </li>
          <li>
            Energia Otimizada: Algoritmos de IA analisam padrões de consumo para
            recomendar estratégias de economia e eficiência energética em
            edifícios e iluminação pública.
          </li>
        </ul>
      </div>
    </div>

    <div class="centered grid grid-template-columns-1 padding-1">
      <img
        class="secondary-image appear-small"
        src="https://i.ibb.co/DgsGHqh/3.jpg"
      />

      <div class="left">
        <h4>CIDADES INTELIGENTES</h4>
        <ul>
          <li>
            Gestão de Tráfego: IA aplica algoritmos para controlar semáforos e
            sinais, otimizando o fluxo de tráfego e reduzindo congestionamentos
            urbanos.
          </li>
          <li>
            Resíduos Sob Controle: IA melhora a logística da coleta de resíduos,
            identificando rotas eficientes e pontos de reciclagem para minimizar
            o impacto ambiental.
          </li>
          <li>
            Transporte Sustentável: Com IA, o sistema de transporte público
            torna-se mais eficaz ao adaptar rotas e horários conforme a demanda,
            melhorando a experiência do usuário.
          </li>
          <li>
            Qualidade do Ar: IA monitora em tempo real poluentes do ar e fornece
            dados para políticas públicas de melhoria da qualidade do ar e saúde
            pública.
          </li>
        </ul>
      </div>
      <img
        class="secondary-image vanish-small"
        src="https://i.ibb.co/DgsGHqh/8.jpg"
      />
    </div>
    <div
      class="centered grid grid-template-columns-1 light-background padding-1"
    >
      <img class="secondary-image" src="https://i.ibb.co/5GCPSgm/3.jpg" />
      <div class="right">
        <h4>ECOLOGIA</h4>
        <ul class="text-too-long">
          <li>
            Alerta de Incêndios: IA analisa imagens de satélite para detectar
            precocemente incêndios florestais, permitindo rápida mobilização de
            recursos para o combate.
          </li>
          <li>
            Previsão de Enchentes: Sistemas de IA predizem possíveis enchentes
            com base em dados meteorológicos e geográficos, alertando populações
            e gestores a tempo.
          </li>
          <li>
            Guarda Ambiental: IA acompanha mudanças ambientais, ajudando a
            proteger espécies em risco e a preservar a diversidade biológica de
            habitats sensíveis.
          </li>
          <li>
            Guardião de Habitats: IA contribui para a monitoração e preservação
            de ecossistemas, fornecendo dados cruciais para a conservação de
            áreas naturais.
          </li>
        </ul>
      </div>
    </div>
    <div class="centered grid grid-template-columns-1 padding-1">
      <img
        class="secondary-image appear-small"
        src="https://i.ibb.co/DRqhJSg/3.jpg"
      />

      <div class="left">
        <h4>SISTEMA FINANCEIRO</h4>
        <ul>
          <li>
            Blindagem Contra Fraudes: A IA reforça a segurança do setor
            financeiro, utilizando algoritmos que aprendem com o tempo para
            identificar e bloquear fraudes.
          </li>
          <li>
            Personalização de Serviços: IA permite a entrega de serviços
            financeiros personalizados, adaptando produtos e aconselhamento às
            necessidades específicas de cada cidadão.
          </li>
          <li>
            Análise de Crédito: IA fornece avaliações de crédito mais acuradas e
            personalizadas, melhorando o acesso a crédito enquanto minimiza
            riscos para o sistema financeiro.
          </li>
          <li>
            Assessoria Financeira: Utilizando grandes volumes de dados e
            aprendizado de máquina, a IA oferece orientação personalizada para
            maximizar a saúde financeira dos indivíduos.
          </li>
        </ul>
      </div>
      <img
        class="secondary-image vanish-small"
        src="https://i.ibb.co/DRqhJSg/5.jpg"
      />
    </div>
    <div
      class="centered grid grid-template-columns-1 light-background padding-1"
    >
      <img class="secondary-image" src="https://i.ibb.co/5GCPSgm/3.jpg" />
      <div class="right">
        <h4>GESTÃO PÚBLICA</h4>
        <ul class="text-too-long">
          <li>
            Inteligência de Dados: IA coleta e analisa complexos conjuntos de
            dados, proporcionando insights valiosos para otimizar serviços e
            informar tomadas de decisão.
          </li>
          <li>
            Simulação de Impacto: A IA possibilita a simulação de políticas e
            medidas antes da execução, possibilitando análises de impacto e
            correção de estratégias com antecedência.
          </li>
          <li>
            Fomento à Transparência: A integração da IA promove a transparência
            governamental, facilitando o acesso do cidadão à informação e o
            escrutínio das decisões públicas.
          </li>
          <li>
            Projeção de Demandas: Algoritmos de IA preveem as necessidades
            futuras da população, capacitando os gestores públicos a planejar e
            responder de maneira proativa e efetiva.
          </li>
          <li>
            Cidadania Ampliada: IA torna mais acessíveis e ágeis os serviços
            públicos, permitindo que a população interaja e resolva demandas com
            mais eficiência.
          </li>
        </ul>
      </div>
    </div>
    <div class="centered padding-1">
      <div style="justify-content: center; margin-bottom: 10vh">
        <h4 style="justify-self: center; margin-left: 0px">Parceiros</h4>
        <div style="display: flex; justify-content: space-around">
          <img
            style="
              justify-self: center;
              cursor: pointer;
              height: 20vh;
              margin-top: 5vh;
            "
            @click="goGroupLink()"
            src="@/assets/grouplink.svg"
          />
          <img
            style="
              justify-self: center;
              cursor: pointer;
              max-height: 15vh;
              margin-top: 5vh;
            "
            src="@/assets/mp.svg"
          /><img
            style="
              align-self: center;
              cursor: pointer;
              max-height: 10vh;
              margin-top: 5vh;
            "
            src="@/assets/Nvidia.png"
          />
        </div>
      </div>
    </div>
    <div
      class="centered grid grid-template-columns-1 light-background padding-1"
    >
      <img
        class="main-image last-image"
        style="margin-bottom: 10vh"
        src="https://i.ibb.co/mF6MrVM/logowideradar.png"
      />

      <div
        class="aligned grid contact-grid"
        style="justify-content: left; padding-bottom: 20vh"
      >
        <h4 id="contato" style="color: #4f4f4f">Contato</h4>
        <div class="grid"></div>
        <div class="flex">
          <i
            class="fa fa-map-marker"
            style="
              color: #4f4f4f;
              font-size: 2vw;
              margin: 15px;
              margin-top: 4vh;
            "
          ></i>
          <p style="color: #4f4f4f">
            Avenida Ipiranga, 40 - Torre Office - Sala 1809 <br />
            Porto Alegre - RS, 91530-000
          </p>
        </div>
        <div class="flex">
          <i
            class="fa fa-whatsapp"
            style="
              color: #4f4f4f;
              font-size: 2vw;
              margin: 10px;
              margin-top: 4vh;
            "
          ></i>
          <p style="color: #4f4f4f">(51) 99955 7717</p>
        </div>
        <div class="flex">
          <i
            class="fa-solid fa-envelope"
            style="
              color: #4f4f4f;
              font-size: 2vw;
              margin: 10px;
              margin-top: 4vh;
            "
          ></i>
          <p style="color: #4f4f4f">comercial@widemind.ai</p>
        </div>
        <router-link to="/about-us">
          <h4
            id="aboutus"
            style="
              background-color: #333;
              border: solid;
              padding: 3%;
              color: #fff;
            "
          >
            Sobre nós
          </h4>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  beforeCreate: function () {
    document.body.className = "home";
  },
  methods: {
    goGroupLink() {
      window.location.href = "https://grouplinknetwork.com/";
    },
  },
};
</script>

<style>
* {
  font-family: "saira condensed", sans-serif;
  font-weight: normal;
}

h4 {
  color: #483429;
  margin-left: 5%;
  font-size: 3rem;
  margin-bottom: 1vh;
}

.secondary-image {
  min-height: 98.8vh;
  max-width: 49.4vw;
  object-fit: cover;
  width: auto;
  height: 100%;
}

.main-image {
  max-width: 30vw;
  padding-left: 10vw;
  padding-top: 15vh;
  padding-right: 10vw;
  border: 0px;
}

.centered {
  justify-content: space-around;
  align-items: flex-end;
}
.middle {
  justify-content: center;
}

.light-background {
  background-color: #ebe8e5;
}

.dark-background {
  background-color: #1c1514;
}
.title-background {
  background-color: #f7f7f7;
}

h3 {
  font-size: 2.5rem;
  font-weight: bold;
}
.grid-template-columns-1 {
  grid-template-columns: 1fr 1fr;
}
.grid {
  display: grid;
}

.dark-background > div > h4 {
  color: #666;
}

.dark-background-title {
  color: #777;
  font-size: 2.5rem;
}

.dark-background > div > ul {
  color: #fff;
}

.dark-background-text {
  color: #fff;
}

ul {
  display: grid;
  width: 30vw;
}
.aligned {
  justify-items: left;
}
ul li {
  font-size: 1.5rem;
  text-align: left;
}
p {
  font-size: 1.2vw;
}
.bar-title {
  margin: 0px;
  font-size: 3.1vw;
  height: 4.7vw;
  background-color: #28465f;
  color: #fff;
}
.text-too-long {
  font-size: 1rem;
}
.left {
  display: grid;
  justify-content: center;
  padding-bottom: 10vh;
}
.right {
  display: grid;
  justify-content: center;
  padding-bottom: 10vh;
}
#aboutus {
  cursor: pointer;
}

@media only screen and (min-width: 1000px) {
  .contact-grid {
    padding-bottom: 10vw !important;
  }
}

@media only screen and (max-width: 1000px) {
  .contact-grid .fa,
  .contact-grid .fa-solid {
    position: relative;
    top: 7px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 950px) {
  .last-image {
    margin-bottom: 22vw !important;
  }
}

@media only screen and (max-width: 800px) and (-webkit-device-pixel-ratio: 1) {
  ul {
    display: grid;
    width: 30vw;
  }

  ul li {
    font-size: 1.5rem;
  }

  .text-too-long {
    font-size: 1rem;
  }

  .last-image {
    margin-bottom: 22vw !important;
  }

  .contact-grid .fa,
  .contact-grid .fa-solid {
    position: relative;
    top: 9px;
  }
}

@media only screen and (orientation: portrait) {
  ul {
    width: 55vw;
    margin-left: 7.5% !important;
  }
  .right {
    justify-content: left;
    padding-left: 10vw;
  }
  .left {
    justify-content: left;
    padding-left: 10vw;
  }
  ul.text-too-long {
    width: 70vw;
    margin-left: 5% !important;
  }

  ul li {
    font-size: 1.5rem;
  }

  ul.text-too-long li {
    padding-right: 10px;
  }

  .grid-template-columns-1 {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)) !important;
  }

  .main-image {
    max-width: 90%;
    padding-left: 5%;
    padding-right: 0;
  }

  .secondary-image {
    max-width: 100vw;
    min-height: 50vh;
  }

  .left .info_top {
    font-size: 8vw;
  }

  .info_subtitle {
    font-size: 6vw !important;
    margin-bottom: 0 !important;
    padding-bottom: 50px;
  }

  h4 {
    font-size: 3rem !important;
    margin-left: 0 !important;
    text-align: center;
    max-width: 80vw;
  }

  .contact-grid {
    grid-template-columns: 1fr;
  }

  .contact-grid h4,
  .contact-grid div {
    text-align: center;
    font-size: 3.5vw !important;
  }

  .contact-grid h4:first-child {
    font-size: 8vw !important;
  }

  .contact-grid div.flex {
    display: block;
  }

  .contact-grid div.flex * {
    display: inline-block;
  }
  p {
    font-size: 3.5vw;
  }

  .contact-grid .fa,
  .contact-grid .fa-solid {
    font-size: 6vw !important;
    position: relative;
    top: 4px;
  }

  .contact-grid .fa-map-marker {
    top: -5px;
  }
}
@media only screen and (min-width: 1200px) and (min-height: 720px) {
  ul li {
    font-size: 2rem;
  }
  h4 {
    font-size: 4vw;
  }
  p {
    font-size: 1.5vw;
  }
}
@media only screen and (orientation: portrait) {
  #contato {
    padding-left: 0px;
  }
  #aboutus {
    padding-left: 0px;
  }
  .aligned {
    justify-items: left;
    padding-left: 30vw;
  }
  ul.text-too-long {
    width: 65vw;
    margin-left: 5% !important;
  }

  ul.text-too-long li {
    padding-right: 10px;
  }

  .grid-template-columns-1 {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)) !important;
  }

  .main-image {
    max-width: 90%;
    padding-left: 5%;
    padding-right: 0;
  }

  .secondary-image {
    max-width: 100vw;
    min-height: 50vh;
  }

  .left .info_top {
    font-size: 8vw;
  }

  .info_subtitle {
    font-size: 6vw !important;
    margin-bottom: 0 !important;
    padding-bottom: 50px;
  }

  h4 {
    font-size: 2.5em !important;
    margin-left: 0 !important;
    text-align: left;
    max-width: 65vw;
    padding-left: 10vw;
  }

  .contact-grid {
    grid-template-columns: 1fr;
  }

  .contact-grid h4,
  .contact-grid div {
    text-align: center;
    font-size: 3.5vw !important;
  }

  .contact-grid h4:first-child {
    font-size: 8vw !important;
  }

  .contact-grid div.flex {
    display: block;
  }

  .contact-grid div.flex * {
    display: inline-block;
  }

  .contact-grid .fa,
  .contact-grid .fa-solid {
    font-size: 6vw !important;
    position: relative;
    top: 4px;
  }

  .contact-grid .fa-map-marker {
    top: -5px;
  }
}

.flex {
  display: flex;
}

.boxed {
  max-width: 23vw;
}

.bigimage {
  background-image: url("https://i.ibb.co/mF6MrVM/logowideradar.png");
  background-size: cover;
  min-height: 80vh;
}

.info_top {
  font-size: 3vw;
  font-family: "saira condensed";
  color: #28465f;
  text-align: right;
}

.info_subtitle {
  margin: 0;
  margin-top: -10vh;
  margin-bottom: 10vh;
  background-color: #f7f7f7;
  padding-left: 10.5vw;
  font-size: 2vw;
  color: #28465f;
  padding-right: 23vw;
}
.appear-small {
  display: none;
}
@media only screen and (orientation: portrait) {
  .appear-small {
    display: block;
  }
  .vanish-small {
    display: none;
  }
}
</style>
