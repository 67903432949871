<template>
  <div class="border-spacing">
    <h1 align="center">WIDE RADAR</h1>
    <div align="left" class="around-button flex">
      <span
        @click="
          openLink(
            'https://drive.google.com/file/d/1tDxRux39O_JiHFyKRHRUSBBsW0CmhPfP/view?usp=sharing'
          )
        "
        class="mdi mdi-download"
      >
      </span>
      <h3
        class="little-hand"
        @click="
          openLink(
            'https://drive.google.com/file/d/1tDxRux39O_JiHFyKRHRUSBBsW0CmhPfP/view?usp=sharing'
          )
        "
      >
        Apresentação do Case
      </h3>
    </div>
    <div align="left" class="around-button flex">
      <span
        @click="
          openLink(
            'https://drive.google.com/file/d/1767i6g_1_qemFtoXrXDelaJjUecY2QY4/view?usp=sharing'
          )
        "
        class="mdi mdi-download"
      >
      </span>
      <h3
        class="little-hand"
        @click="
          openLink(
            'https://drive.google.com/file/d/1767i6g_1_qemFtoXrXDelaJjUecY2QY4/view?usp=sharing'
          )
        "
      >
        Termo de exclusividade
      </h3>
    </div>
    <div align="left" class="around-button flex">
      <span
        @click="
          openLink(
            'https://drive.google.com/file/d/1R9ciwvfq01nM0QG9NIvFRYKEVWn5Vao1/view?usp=sharing'
          )
        "
        class="mdi mdi-download"
      >
      </span>
      <h3
        class="little-hand"
        @click="
          openLink(
            'https://drive.google.com/file/d/1R9ciwvfq01nM0QG9NIvFRYKEVWn5Vao1/view?usp=sharing'
          )
        "
      >
        Wideradar Institucional
      </h3>
    </div>
    <h3>Entendendo nossa solução</h3>
    <div>
      <span class="background-number left-number">1</span>
    </div>
    <p align="justify">
      As ações dos municípios no que tange a saúde da população são reativas e
      têm baixa resolutividade. Isto ocorre porque não existe instrumento capaz
      de avaliar e localizar os riscos de saúde dos cidadãos. Com isto,
      geralmente, os Municípios do Brasil enfrentam diariamente os seguintes
      problemas: o sistema de saúde fica sobrecarregado, o que resulta em
      aumento dos custos, lentidão nas filas de consultas especializadas, piora
      dos quadros gerais dos pacientes, emergências superlotadas, ações que não
      têm aderência pela população, ocorrência de exames em duplicidade, agentes
      de saúde desvalorizados, não compreensão do perfil de saúde da área de
      atuação dos agentes, desconhecimento das necessidades do município,
      baseado em evidências reais, e ações de saúde não baseadas em evidências,
      não existência de um monitoramento contínuo da população, não existência
      de acompanhamento de casos graves no ambiente domiciliar, perda de
      agilidade de resposta das ações de saúde em casos de epidemias, falta uma
      comunicação direta com o cidadão, sobrecarga dos postos de saúde e
      emergência, falta de qualificação das anamneses, baixa resolutividade do
      sistema de saúde.
    </p>
    <p align="justify">
      A Wide Radar, com seu software proprietário, consegue monitorar a saúde
      dos cidadãos, localizando-os geograficamente e identificando os riscos
      associados à sua saúde. Com isto, permite ações focadas nas necessidades
      reais dos cidadãos, fazendo melhor uso dos recursos do Município,
      produzindo uma medicina mais resolutiva e evitando desfechos urgentes,
      desafogando o Sistema de Saúde, ajudando a garantir o recebimento das
      verbas de programas de saúde provenientes dos governos Estadual e Federal.
    </p>
    <div class="light-background">
      <p align="justify">
        O sistema da Wide Radar inova ao trazer a visualização em tempo real e
        uma gestão baseada em evidências. Além disso, a nossa proposta insiste,
        estatisticamente, na obtenção dos dados na forma de censo (e não apenas
        amostragem) de modo a capturar a não perder nenhum indivíduo do banco de
        dados, especialmente os casos extremos e mais distantes das médias! Nós
        acreditamos que a tecnologia vai permitir que sistemas de saúde sejam
        capazes de cuidar de TODA a sua população.
      </p>
    </div>

    <div>
      <span class="background-number right-number">2</span>
    </div>
    <p align="justify">
      O custo para utilização deste software está detalhado na tabela anexa, de
      modo a atender às necessidades dos Municípios.
    </p>
    <div class="light-background smaller-box">
      <p align="justify">
        O sistema da Wide Mind é altamente escalável e confiável, visto que a
        hospedagem e processamento são realizados em nuvem (Google Cloud). Além
        do baixo impacto ambiental pela otimização do uso dos recursos
        computacionais, o custo do sistema por habitante diminui com o número
        total de habitantes, ao mesmo tempo em que o poder da Inteligência
        Artificial aumenta com mais habitantes e dados cadastrados.
      </p>
    </div>
    <div>
      <span class="background-number left-number">3</span>
    </div>
    <p align="justify">
      O input dos dados é feito pelos agentes (e não pelo cidadão) via web
      browser ou por meio de aplicativo Android (Google Play Store) ou iOS
      (Apple Store) que pode ser instalado em smartphones ou tablets. Cada
      agente possui uma conta para acessar a aplicação. O gestor possui conta de
      tipo diferente, com mais permissões. Questões relacionadas à anonimização,
      permissões (consentimento), privacidade e segurança estão todas de acordo
      com LGPD. A geolocalização é obtida por meio do GPS dos dispositivos dos
      agentes ou do endereço fornecido pelos cidadãos. A partir daí o sistema da
      Wide Radar, baseado nas diretrizes e na inteligência artificial
      proprietária, processa, analisa, determina riscos e ações e faz o
      geoprocessamento, que gera um mapa de saúde do município, disponível num
      dashboard que o gestor pode acessar em tempo real via web browser.
    </p>
    <div class="light-background">
      <p>
        No dashboard o gestor dispõe de um mapa e gráficos gerados
        automaticamente com base nos dados atualizados do seu município, assim
        como uma timeline que permite a consulta do histórico e um campo de
        texto onde o gestor pode incluir anotações relevantes.
      </p>
    </div>
    <div>
      <span class="background-number left-number">4</span>
    </div>
    <p align="justify">
      Para desenvolver este trabalho, o sistema da Wide Radar precisa ser
      alimentado com os dados de saúde do Município, coletados pelos agentes de
      saúde junto aos cidadãos, por meio da utilização de um tablet ou um
      celular. Em cada município, a quantidade de agentes varia, obedecendo a
      regra legal mínima de 1 agente para cada 400 cidadãos, até o limite máximo
      de 1 agente para cada 750 cidadãos.
    </p>
    <div class="light-background right-box">
      <p align="justify">
        A integração do sistema de coleta da Wide Radar com as equipes de
        agentes comunitários de saúde é um dos fatores que viabiliza o custo
        reduzido, assim como aumenta a eficácia das sugestões, ações, assim como
        correções e ajustes no sistema com base no feedback dos profissionais de
        saúde que atuam na ponta.
      </p>
    </div>
    <p align="justify">
      A falta de geoprocessamento de saúde impede o monitoramento das pessoas
      pelos agentes, não sendo possível ações direcionadas aos indivíduos que
      mais necessitam
    </p>
    <div>
      <span class="background-number right-number">5</span>
    </div>
    <div class="light-background smaller-box">
      <p align="justify">
        Wide Radar é a única empresa do ramo que, por meio da utilização de
        inteligência artificial, analisa a grande quantidade de dados coletados
        e entrega soluções de saúde específicas para cada município, de forma
        ágil e precisa
      </p>
    </div>
    <p align="justify">
      A Wide Radar coleta, analisa e gera relatórios de saúde nos formatos
      exigidos pelos programas federais e estaduais (tais como o Previne Brasil,
      Rede Bem Cuidar e outros). O sistema da Wide Radar atribui o grau adequado
      de risco para os pacientes, dando, inclusive, suporte ao médico clínico
      geral na resolução dos quadros clínicos mais simples, e evitando a
      necessidade do encaminhamento para especialista, o que diminui o número de
      consultas especializadas desnecessárias. O sistema da Wide Radar
      identifica exames que já tenham sido realizados, e que não tenham sido
      retirados, sugerindo a retirada de exames efetuados antes da solicitação
      de exames novos, para diminuir a ocorrência de duplicidade. Estas tarefas
      resultam em economia para o Município e um desafogamento da rede de saúde.
    </p>
    <p align="justify">
      Para os agentes de saúde, o sistema da Wide Radar faz a coleta e análise
      da performance dos agentes em campo, permitindo o levantamento do perfil
      de saúde da sua área de atuação; a implementação de necessidades baseadas
      em evidências reais; a remuneração variável a partir de métricas de
      resultados do trabalho; praticidade na obtenção de informações dos
      pacientes; valorização dos agentes de saúde.
    </p>
    <p align="justify">
      Para os gestores de saúde, o sistema da Wide Radar propicia a compreensão
      das necessidades reais do município; a justificativa de ações baseada em
      evidências; o gerenciamento e a supervisão do trabalho das equipes de
      saúde; o monitoramento contínuo da população; a agilidade na resposta de
      ações de saúde em casos de epidemias; a identificação e acompanhamento de
      casos graves; a previsão de necessidade de contratação de especialistas; o
      geoprocessamento da Wide Radar localiza os indivíduos no mapa da cidade, o
      que permite ao gestor analisar o perfil de saúde por região ou bairro,
      além do município como um todo.
    </p>
    <p align="justify">
      Para a Prefeitura, o sistema da Wide Radar permite o monitoramento da
      gestão de saúde com estatísticas online; a gestão do trabalho em tempo
      real; a comunicação direta com o cidadão; e a exposição do município como
      referência em gestão de saúde.
    </p>
    <p align="justify">
      Para a população, o sistema da Wide Radar gera ações de saúde com máxima
      eficácia, pois elas são geradas baseadas em evidências, a partir dos dados
      coletados e das diretrizes atuais. E, individualmente, os cidadãos recebem
      melhor cuidado e um atendimento com maior resolutividade, pois o sistema
      da Wide Wind auxilia o médico clínico geral a resolver muitos dos casos já
      na primeira consulta; além de prevenir e antecipar de eventos graves,
      propiciando o engajamento da população em ações multidisciplinares.
    </p>
    <p align="justify">
      Além disto, o sistema da Wide Radar, para os profissionais de saúde em
      geral, reduz a sobrecarga nos postos de saúde e emergências; qualifica as
      anamneses com incremento da resolutividade do sistema; minimiza a
      utilização de especialistas; permite a prática de medicina 4P
      (personalizada, preventiva, preditiva e participativa). E, especificamente
      nos cenários de epidemias e pandemias, o sistema permite a identificação
      de populações vulneráveis; o impedimento de lockdowns generalizados; o
      isolamento seletivo de bairros e indivíduos; e a capacidade de geração de
      estatísticas com agilidade e precisão. Desta forma, o sistema representará
      para Município, economia, agilidade, precisão, e vidas salvas.
    </p>
    <div class="light-background">
      <p align="justify">
        Com o sistema da Wide Radar funcionando plenamente, o gestor do
        município terá a capacidade de detectar novas epidemias logo nos seus
        estágios iniciais, identificar os indivíduos afetados e localizá-los
        geograficamente, o que permite uma intervenção precisa para conter a
        ameaça, evitando a necessidade de lockdown, que possui consequências
        graves para a saúde mental da população e para a economia local, como
        vimos ao longo da pandemia de Covid-19.
      </p>
    </div>
    <div>
      <span class="background-number right-number">6</span>
    </div>
    <p align="justify">
      O sistema da Wide Radar coleta dados de saúde da população do município
      contratante. Idealmente, é feita a coleta da população inteira (como num
      censo) por meio das visitas dos agentes comunitários de saúde. No mapa
      gerado por geoprocessamento, o gestor pode acompanhar a população inteira,
      com sua localização geográfica (cada cidadão é um ponto no mapa) e filtrar
      por condição de interesse (por exemplo, apenas pessoas vacinadas, apenas
      diabéticos com risco cardiovascular elevado etc.). O sistema da Wide Radar
      calcula automaticamente com uso de inteligência artificial e big data os
      riscos específicos daquela população e sugere alguns itens e ações de
      maior relevância para o gestor. Na medida em que novas coletas de dados
      são feitas, é possível acompanhar a evolução numa linha do tempo do quadro
      geral da saúde no município e do resultado das ações tomadas. A Wide Radar
      oferece assistência permanente online aos agentes em campo por meio de
      técnicos dedicados a essa tarefa com profundo conhecimento da solução. A
      maior parte das dúvidas são resolvidas no mesmo dia e solicitações mais
      complexas são resolvidas na mesma semana. Visitas ao município para
      treinamento e suporte podem ser agendadas.
    </p>
    <div class="light-background">
      <p align="justify">
        A equipe da Wide Radar é inteiramente formada por engenheiros e
        especialistas, os quais estão disponíveis para auxiliar os profissionais
        de saúde na implementação e uso do sistema, e na atualização rápida de
        melhorias do sistema.
      </p>
    </div>
    <div>
      <span class="background-number left-number">7</span>
    </div>
    <p align="justify">
      A coleta de dados é contínua, pois é feita pelos agentes de saúde. O
      sistema está online o tempo todo, e é atualizado conforme os novos inputs
      obtidos pelos agentes. Periodicamente, relatórios de compliance são
      gerados. Os custos da equipe técnica e dos servidores da Wide Radar são
      mensais.
    </p>
    <div class="light-background right-box">
      <p align="justify">
        A frequência dos relatórios de compliance pode ser acordada com os
        municípios. Além disso, apesar de o dashboard sempre mostrar a versão
        mais atual dos dados, é possível para o gestor visualizar o histórico da
        sua população por meio de uma timeline que contém as coletas de dados
        realizadas ao longo do tempo
      </p>
    </div>
    <div>
      <span class="background-number left-number">8</span>
    </div>
    <p align="justify">
      A partir dos dados coletados, é possível gerar centenas de estatísticas
      diferentes. Cada filtro selecionado pelo gestor no dashboard gera um novo
      mapa, junto com a estatística relacionada àquele mapa e uma caixa de
      textos para que os gestores incluam seus comentários e análise. A
      inteligência artificial da Wide Radar pode preencher parcialmente essas
      caixas com sugestões. Além disso, as estatísticas solicitadas por
      programas oficiais (tais como Previne Brasil, Rede Bem Cuidar, combate à
      dengue etc.) aparecem como opção de filtro para o gestor, satisfazendo
      precisamente os requisitos de cada um destes programas. A garantia das
      verbas obtidas por esses programas são uma fonte de recursos para o
      município permitidas pelo sistema da Wide Radar. Outras métricas incluem a
      diminuição de gastos com partos desnecessários, exames duplicados,
      consultas desnecessárias com especialistas, redução de casos graves,
      manejo adequado de pacientes crônicos (e dos custos associados), como
      mencionado anteriormente.
    </p>
    <div class="light-background">
      <p align="justify">
        Essas métricas , filtros e estatísticas relevantes são algumas das mais
        importantes para os nossos clientes. Contudo, centenas de outras podem
        ser geradas, conforme o perfil do município e as necessidades e metas da
        Secretaria de Saúde.
      </p>
    </div>
    <div>
      <span class="background-number right-number">9</span>
    </div>
    <p align="justify">
      O treinamento dos agentes é realizado pela Wide Radar por meio de visita
      presencial. Novas visitas para treinamento ou assistência técnica podem
      ser agendadas, além do atendimento online com técnicos (engenheiro de
      software).
    </p>
    <div class="light-background smaller-box">
      <p align="justify">
        O treinamento presencial, as visitas agendadas e o atendimento constante
        à distância pelos engenheiros da Wide Radar existem para garantir
        agilidade e eficácia no atendimento das demandas locais.
      </p>
    </div>
    <div>
      <span class="background-number left-number">10</span>
    </div>
    <p align="justify">
      No caso de o município não dispor do número necessário de agentes ou dos
      dispositivos necessários para a coleta adequada dos dados em tempo hábil,
      a Wide Radar irá se adequar à equipe de agentes existentes e com os
      dispositivos necessários.
    </p>
    <div class="light-background right-box">
      <p align="justify">
        Embora o objetivo da Wide Radar seja alocar a maioria dos agentes
        comunitários de saúde e monitorar 100% da população, essa pode não ser a
        realidade do município. O sistema da Wide Radar não depende da coleta
        total. Cada cidadão é analisado individualmente pelo sistema e assim que
        os dados são coletados a saúde do cidadão passa a ser monitorada em
        tempo real pelos profissionais de saúde.
      </p>
    </div>
    <div>
      <span class="background-number left-number">11</span>
    </div>
    <p>
      A Wide Radar é uma empresa de tecnologia de baixo custo energético e
      essencialmente digital. Nenhum dos processos da empresa exige o uso de
      papel, produtos químicos ou outros materiais possivelmente tóxicos ou
      prejudiciais ao meio ambiente. Os nossos servidores contratados ficam
      hospedados em máquinas do tipo “low CO2” (baixo carbono) do Google Cloud.
    </p>
    <div class="light-background right-box">
      <p>
        O uso de sistemas computacionais em todas as atividades da sociedade
        continua crescendo, assim como a necessidade de processamento e o custo
        ambiental da fabricação do hardware, de geração de energia elétrica
        entre outros. Esforços globais têm sido feitos para otimizar esses
        sistemas, tanto do ponto de vista de hardware quanto de software, e
        ambos são uma preocupação da Wide Radar. Nossos algoritmos são
        otimizados para minimizar a necessidade de processamento e o nosso
        hardware é escolhido conforme sua pegada de carbono, ou seja, em ambos
        os casos reduzindo ao máximo o possível impacto ambiental do nosso
        sistema.
      </p>
    </div>
    <div>
      <span class="background-number left-number">12</span>
    </div>
    <p>
      Assim, a Wide Radar apresenta uma solução extremamente flexível e modular,
      na resolução dos problemas de saúde dos Municípios. Além das
      funcionalidades descritas, outras que o Município tenha interesse podem
      ser implementadas. Uma versão personalizada do software pode ser criada
      para atender demandas específicas dos gestores de saúde.
    </p>
    <div class="light-background right-box">
      <p>
        A natureza modular do sistema permite que cada filtro, variável de
        interesse e mesmo os dados de entrada sejam alterados de modo a se
        adequarem da melhor forma às necessidades do município. Algumas
        funcionalidades dependem da inclusão de certas variáveis, mas muitas
        outras são independentes, de modo que podem ser adicionadas ou removidas
        de forma segura e eficaz, tanto na etapa de implementação do sistema,
        como quando já está em operação, por meio de interação constante com os
        gestores e profissionais de saúde, de modo que a ferramenta não evolui
        só a partir dos dados, ela consegue incorporar também rapidamente as
        melhorias sugeridas pelos profissionais que estão atuando diretamente no
        projeto.
      </p>
    </div>
  </div>
</template>

<style scoped>
* {
  font-family: "now", sans-serif;
  font-weight: normal;
}

h1,
h2,
h3,
h4 {
  font-family: "anton";
  font-weight: normal;
  color: #ffffff;
}

.border-spacing {
  margin-left: 15%;
  margin-right: 10%;
}

p {
  padding-top: 1%;
  padding-bottom: 1%;
  color: #ffffff;
}

h1 {
  font-size: 20mm;
}

h3 {
  font-size: 8mm;
}

.light-background {
  background-color: #74bddb;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-right: 3%;
  padding-left: 3%;
}

th,
td {
  border: 2px solid #fff;
  padding: 1%;
}

table {
  color: #ffffff;
}

th {
  background-color: #74bddb;
}

.background-number {
  color: #74bddb;
  font-size: 80mm;
  z-index: -1;
  position: absolute;
  font-weight: 1000;
}

.left-number {
  left: 8%;
}

.right-number {
  left: 74%;
}

.right-box {
  margin-left: 20%;
}

.smaller-box {
  max-width: 65%;
}

.mdi {
  border-radius: 50px;
  background-color: #ffffff;
  padding: 10px;
  height: 15px;
  margin-top: 35px;
  margin-right: 5px;
  color: #74bddb;
}
.mdi:hover {
  cursor: pointer;
}

.little-hand {
  margin-bottom: 0px;
  cursor: pointer;
}

.little-hand:hover {
  cursor: pointer;
}
</style>

<script>
export default {
  name: "DetailView",
  beforeCreate: function () {
    document.body.className = "detail";
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank").focus();
    },
  },
};
</script>
