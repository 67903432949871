<template>
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link href="https://fonts.cdnfonts.com/css/garet" rel="stylesheet" />
    <link href="https://fonts.cdnfonts.com/css/now" rel="stylesheet" />
    <link href="https://fonts.cdnfonts.com/css/anton" rel="stylesheet" />
    <link
      href="https://fonts.cdnfonts.com/css/saira-condensed"
      rel="stylesheet"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css"
      integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w=="
      crossorigin="anonymous"
      referrerpolicy="no-referrer"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
    />
  </head>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: "now", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

a {
  text-decoration: none;
  width: -webkit-fill-available;
  h4:hover {
    background-color: #2c3e50 !important;
  }
  h4 {
    transition: 0.5s ease;
  }
}
body {
  margin: 0;
}

body.home {
  background-color: #fff;
  color: #000;
}
body.about,
body.detail {
  background-color: #0087cb !important;
}
</style>
